.current-date {
  background-color: yellow;
  font-weight: bold;
}

.event-date {
  background-color: red;
  color: white;
}

.tile {
  height: 80px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.day {
  display: none;
  margin-bottom: 5px;
}

.tile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.event-time {
  font-size: 12px;
}

.react-calendar__tile--now .react-calendar__tile-label {
  display: none;
}

.selected-date {
  background-color: lightblue;
}

.calendar-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calendar-container {
  margin-bottom: 20px;
}

.event-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 12px;
  width: 356px;
  height: 56px;
  background: #F1813C;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
}



